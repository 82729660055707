export let demos = [
  {
    title: 'demos.demo17.title',
    description: 'demos.demo17.description',
    imgSrc: 'demo/padel-service.png',
    homepageLink: 'https://demo17.bokamera.se/',
    username: 'demo17@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    } 
  },
  
  {
    title: 'demos.demo8.title',
    description: 'demos.demo8.description',
    imgSrc: 'demo/apartment.jpg',
    homepageLink: 'https://demo8.bokamera.se/',
    username: 'demo8@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo1.title',
    description: 'demos.demo1.description',
    imgSrc: 'demo/hairdresser.png',
    homepageLink: 'https://demo1.bokamera.se/',
    username: 'demo@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo11.title',
    description: 'demos.demo11.description',
    imgSrc: 'demo/car-repair.jpg',
    homepageLink: 'https://demo11.bokamera.se/',
    username: 'demo11@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo9.title',
    description: 'demos.demo9.description',
    imgSrc: 'demo/massage.jpg',
    homepageLink: 'https://demo9.bokamera.se/',
    username: 'demo9@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo10.title',
    description: 'demos.demo10.description',
    imgSrc: 'demo/conference-room.jpg',
    homepageLink: 'https://demo10.bokamera.se/',
    username: 'demo10@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo5.title',
    description: 'demos.demo5.description',
    imgSrc: 'demo/yogatraining.png',
    homepageLink: 'https://demo5.bokamera.se/',
    username: 'demo5@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo18.title',
    description: 'demos.demo18.description',
    imgSrc: 'demo/bicycle-workshop-service.jpg',
    homepageLink: 'https://demo18.bokamera.se/',
    username: 'demo18@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  

  {
    title: 'demos.demo4.title',
    description: 'demos.demo4.description',
    imgSrc: 'demo/sport.jpg',
    homepageLink: 'https://demo4.bokamera.se/',
    username: 'demo4@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo6.title',
    description: 'demos.demo6.description',
    imgSrc: 'demo/adventure.jpg',
    homepageLink: 'https://demo6.bokamera.se/',
    username: 'demo6@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  
  {
    title: 'demos.demo12.title',
    description: 'demos.demo12.description',
    imgSrc: 'demo/dentist.jpg',
    homepageLink: 'https://demo12.bokamera.se/',
    username: 'demo12@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo7.title',
    description: 'demos.demo7.description',
    imgSrc: 'demo/bicycle.jpg',
    homepageLink: 'https://demo7.bokamera.se/',
    username: 'demo7@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo14.title',
    description: 'demos.demo14.description',
    imgSrc: 'demo/washing-service.jpg',
    homepageLink: 'https://demo14.bokamera.se/',
    username: 'demo14@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo19.title',
    description: 'demos.demo19.description',
    imgSrc: 'demo/dog-sport-facility.jpg',
    homepageLink: 'https://demo19.bokamera.se/',
    username: 'demo19@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo20.title',
    description: 'demos.demo20.description',
    imgSrc: 'demo/cosmetologist.jpg',
    homepageLink: 'https://demo20.bokamera.se/',
    username: 'demo20@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo13.title',
    description: 'demos.demo13.description',
    imgSrc: 'demo/rescue-service.jpg',
    homepageLink: 'https://demo13.bokamera.se/',
    username: 'demo13@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  
  {
    title: 'demos.demo16.title',
    description: 'demos.demo16.description',
    imgSrc: 'demo/driving-school-service.jpg',
    homepageLink: 'https://demo16.bokamera.se/',
    username: 'demo16@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo3.title',
    description: 'demos.demo3.description',
    imgSrc: 'demo/restaraunt.jpg',
    homepageLink: 'https://demo3.bokamera.se/',
    username: 'demo3@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  {
    title: 'demos.demo2.title',
    description: 'demos.demo2.description',
    imgSrc: 'demo/cabin.jpg',
    homepageLink: 'https://demo2.bokamera.se/',
    username: 'demo2@bokamera.se',
    password: 'demo12',
    get adminLink() {
      return `https://admin.bokamera.se`
    }
  },
  
 
];

export let services = [
  {
    title: 'services.internetBooking.title',
    description: 'services.internetBooking.description',
    imgSrc: 'services/internet-booking.png',
  },
  {
    title: 'services.ownWebsite.title',
    description: 'services.ownWebsite.description',
    imgSrc: 'services/own-website.png',
  },
  {
    title: 'services.reminders.title',
    description: 'services.reminders.description',
    imgSrc: 'services/reminders.png',
  },
  {
    title: 'services.support.title',
    description: 'services.support.description',
    imgSrc: 'services/support.png',
  },
  {
    title: 'services.synchronization.title',
    description: 'services.synchronization.description',
    imgSrc: 'services/synchronization.png',
  },
  {
    title: 'services.customerRegister.title',
    description: 'services.customerRegister.description',
    imgSrc: 'services/customer-register.png',
  },
  {
    title: 'services.calendar.title',
    description: 'services.calendar.description',
    imgSrc: 'services/calendar.png',
  },
  {
    title: 'services.apps.title',
    description: 'services.apps.description',
    imgSrc: 'services/apps.png',
  },
  {
    title: 'services.newsletter.title',
    description: 'services.newsletter.description',
    imgSrc: 'services/newsletter.png',
  },
  {
    title: 'services.reports.title',
    description: 'services.reports.description',
    imgSrc: 'services/reports.png',
  },
  {
    title: 'services.statistics.title',
    description: 'services.statistics.description',
    imgSrc: 'services/statistics.png',
  },
  {
    title: 'services.receipt.title',
    description: 'services.receipt.description',
    imgSrc: 'services/receipt.png',
  },
  {
    title: 'services.online-payment.title',
    description: 'services.online-payment.description',
    imgSrc: 'services/online-payment.png',
  },
  {
    title: 'services.fb-integration.title',
    description: 'services.fb-integration.description',
    imgSrc: 'services/fb-integration.png',
  },
  {
    title: 'services.language-version.title',
    description: 'services.language-version.description',
    imgSrc: 'services/language-version.png',
  },
  {
    title: 'services.promo-code.title',
    description: 'services.promo-code.description',
    imgSrc: 'services/promo-code.png',
  },
  {
    title: 'services.rco.title',
    description: 'services.rco.description',
    imgSrc: 'services/lock.png',
  },
  {
    title: 'services.queue.title',
    description: 'services.queue.description',
    imgSrc: 'services/queue.png',
  },
];

export let pricingPlans = {
  free: {
    title: 'pricingPlans.free.title',
    price: '0',
  },
  standard: {
    title: 'Standard',
    price: '199',
  },
  premium: {
    title: 'Standard',
    price: '299',
  },
};
